html,
body {
  margin: 0;
  padding: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  //   "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  //   "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-family: 'Ubuntu', sans-serif!important;
  color: #0E1449!important;
}
.cursor-pointer{
  cursor: pointer;
}
.riga-trova-impresa{
  margin-bottom: 15px; 
  padding: 15px 15px 15px 15px; 
  background-color: #e3e6f0;
}
.icon-azienda-trova-impresa{
  font-size: 2rem;
  margin: auto;
  display: block;
  text-align: center;
}
.component-maxwidth{
  width: 100%;
}
.heigth-4{
  height: 4em;
}
.boldtesto{
  font-weight: bold;
}
.textcenterModalElenco{
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #e3e6f0;
  margin-top: 15px;
  margin-bottom: 15px;
}
.heigthRigaElenco{
  height: 30px;
}
.icon-size-normal{
  font-size: 2rem;
}
.icon-size-big{
  font-size: 3rem;
}
.icon-size-big-2{
  font-size: 2rem;
}
.icon-size-small{
  font-size: 1.2rem;
}
.font-size-1{
  font-size: 1.0rem;
}
.color-icon-notfound{
  color: #db2723;
}

.color-icon-notfound-gray{
  color: #596262;
}
.color-icon-green{
  color: #008000b0!important;
}
.color-icon-orange{
  color: #FF8300!important;
}
.color-icon-yellow{
  color: #f8f32b;
}
.color-icon-red{
  color: #D9D9D9;
}
.color-icon-red-bil{
  color: #db2723!important;
}
.color-icon-default{
  color: #4e73df;
}
.color-icon-gray{
  color: #858796;
}
.font-size-1-2-bold{
  font-size: 1.2em;
  font-weight: bold;
}
.font-size-1-2{
  font-size: 1.2em;
}
.font-size-1-5{
  font-size: 1.5em!important;
}
.padding-bottom20{
  padding-bottom: 20px;
}
.no-padding{
  padding: 0px;
}

.fieldset {
  overflow: hidden
}

.some-class {
  float: left;
  clear: none;
}

label {
  float: left;
  clear: none;
  display: block;
  padding: 0px 1em 0px 8px;
}

/* The container */
.containerradio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

  /* Hide the browser's default radio button */
  .containerradio input[type=radio] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
  }

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 25px;
  background-color: #eee;
  border-radius: 0%;
}

/* On mouse-over, add a grey background color */
.containerradio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerradio input:checked ~ .checkmark {
  background-color: #db2723;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerradio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerradio .checkmark:after {
  top: 42%;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 0%;
  background: white;
}

.ampiezzamax {
  width: 100%;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  // color: #fff!important;
  // background-color: #3d63d2!important;
  // border-color: #3d63d2 #3d63d2 #fff!important;
  color: #0E1449 !important;
  font-weight: bold;
  // background-color: transparent!important;
  border-bottom-color: #0E1449 !important;
  border-bottom: 2px solid!important;
  border-top: none;
  border-left: none;
  border-right: none;

}
.border-right-5{
  border-right: 2px solid;
}
.decoration-none{
  text-decoration: none!important;;
}
.padding-zero{
  padding: 0px!important;
}
.border-left-8{
  border-left: 8px solid;
}
.border-left-8-green{
  border-left: 8px solid #008000b0;
  border-radius: 15px;
}
.border-left-8-red{
  border-left: 8px solid #db2723;
  border-radius: 15px;
}
.center-block-custom{
  display: block;
  margin: auto;
}

// .main-app {
//   margin: 2% 10%;
//   border: 1px ridge #464141;
//   padding: 5%;
//   font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
//   font-size: 20px;
//   color: #464141;
//  }
  
 .container {
  // border-bottom: #fff 2px ridge;
 }
  
 .pagination {
  margin-top: 45px;
  //margin-left: -40px;
  display: flex;
  list-style: none;
  outline: none;
  
 }

  
 .pagination>.active>a {
  background-color: #0E1449;
  color: #f8f9fc!important;
 }
  
 .pagination>li>a {
  //border: 1px solid #fff;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  color: #0E1449!important;
 }
  
 .pagination>li>a, .pagination>li>span {
  color:#1cc88a;
  background-color: #f8f9fc;
  //border-color: #ccc;
  border-radius: 10px;
 }
 .background-custom-card{
    background-color: #f8f9fc;
    width: 100%;
    overflow-x: hidden;
 }
 .background-custom-card-no-overflow{
  background-color: #f8f9fc;
  width: 100%;
}
 .background-row-white{
  background-color: #fff;
 }
 .css-13cymwt-control{
  min-height: 2.5em;
 }
 .basic-multi-select{
  width: 100%;
 }
 .box-ricerca-elenco{
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px
 }
 .title-style-elenco{
    font-weight: bold;
    font-size: large;
    text-align: center;
 }
 .title-campo-style-elenco{
  font-weight: bold;
 }
 .icon-alert{
    color: orange;
    font-size: 4em;
    text-align: center;
 }
 .icon-confirm{
  color: #008000b0;
  font-size: 4em;
  text-align: center;
}
.icon-confirm-small{
  color: #008000b0;
  font-size: 3em;
  text-align: center;
}
 .text-alert-center-bold{
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
 }
 .text-alert-center{
  text-align: center;
  font-size: 1em;
}
.larghezza-100{
  width: 100%;
}
.allinea-testo-sx{
  text-align: left;
}
.link-accedi:hover{
  color: #0f6848
}
.marginb-15{
  margin-bottom: 15px;
}
.curser-pointer{
  cursor: pointer;
}

.react-tags__suggestions {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px; /* Imposta l'altezza massima desiderata */
  overflow-y: auto;
}

.shadow-margin-zero{
  margin-right: 0rem!important;
  margin-left: 0rem!important;
}

.font-size-xlg{
  font-size: x-large;
}
.font-size-12{
  font-size: 12px;
}

.icon-green:hover{
  color: #008000b0;
  opacity: 0.7;
}
.align-center{
  text-align: center;
}
.align-right{
  text-align: right;
}
.margin-row{
  margin-right: -0.5rem!important;
  margin-left: -0.5rem!important;
}

.dettaglio nav a {
  color: #D9D9D9 !important;
  border-bottom: 1px transparent solid!important; 
  /* background-color: #3d63d2 !important; */
  /*border-bottom-color: #008000b0 !important; */
}
// .dettaglio nav a .active {
//   color: #008000b0 !important;
//   background-color: transparent!important;
//   border-bottom-color: #008000b0 !important;
//   border-bottom: 2px solid !important;
  
// }
.display-block{
  display: block;
}

.dettaglio .nav-tabs {
  border-bottom: none!important;
}
.dettaglio .mb-3{
  width: 80%;
}
.altezza-150{
  max-height: 150px;
}
.dimensione_mappa{
  width: 100%;
  height: 300px;
}
.dimensione_mappa_ul{
  width: 100%;
  height: 400px;
}
.text-align-right{
  text-align: right;
}
.float-right{
  float: right;
}
.heigth-max{
  height: 100%!important;
}
.dimensione-chart{
    height: 90%!important;
    width: 90%!important;
    display: block;
    box-sizing: border-box;
}
.margin-left-rigth-15{
  margin-left: 15px;
  margin-right: 15px;
}

.margin-left-7{
  margin-left: 7.5px;
}
.margin-rigth-7{
  margin-right: 7.5px;
}
.margin-bottom-20{
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .margin-left-rigth-15{
    margin-left: -12px;
    margin-right: -12px;
  }
  .margin-left-7{
    // margin-left: 7.5px;
  }
  .margin-rigth-7{
    //margin-right: 7.5px;
  }
  .position-card-center{
    top: 0!important;
  }
}

.position-card-center{
  top: 35%;
  position: relative;
}

.red-icon{
  background-color: red;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top:50%
}
.green-icon{
  background-color: green;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top:50%
}
.yellow-icon{
  background-color: yellow;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top:50%
}
.orange-icon{
  background-color: orange;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top:50%
}
.text-deco-none{
  text-decoration: none;
}
.posizioni-lista{
  color: #0E1449;
  font-weight: bold;
}
.posizioni-lista:hover{
  color: #1cc88a;
  font-weight: bold;
  opacity: 7;
  text-decoration: none!important;
}
.icon-profilo{
  font-size: 30px;
  color: #0E1449;
}
.position-messaggio-errore{
  top: 20%;
  position: relative;
}

.bg-gradient-primary {
  // background-color: #1cc88a!important;
  // background-image: linear-gradient(180deg, #1cc88a 10%, #33e1a1 100%)!important;
  background: radial-gradient(39.33% 57.27% at 50% 50%, #D9D9D9 17.5%, #FFFFFF 100%);

}
.bg-gradient-primary-sidebar {
  // background-color: #1cc88a!important;
  // background-image: linear-gradient(180deg, #1cc88a 10%, #33e1a1 100%)!important;
  background: linear-gradient(250.21deg, #0E1449 10.28%, #070B32 63.23%);
}
.sidebar-heigth{
  height: 100%;
}
.border-left-primary {
  border-left: 0.25rem solid #0E1449 !important;
}
.text-primary {
  color: #5a5c69  !important;
}

.btn-paypal{
  background-color: #0E1449;
  color: #ffffff;
  padding: 10px;
  border-radius: 0.35rem;
}

.btn-nexi{
  background-color: #0E1449;
  color: #ffffff;
  padding: 10px;
  border-radius: 0.35rem;
}

.pacchetto-aggiuntivo{
  color: #0E1449;
  font-weight: bold;
}

.color_cel{
  color: #36b9cc;
}

.btn-paypal:hover{
  background-color: #5a5c69!important;
  color: #ffffff;
  padding: 10px;
  border-radius: 0.35rem;
  text-decoration: none;
  cursor: pointer;
}

.btn-nexi:hover{
  background-color: #5a5c69!important;
  color: #ffffff;
  padding: 10px;
  border-radius: 0.35rem;
  text-decoration: none;
  cursor: pointer;
}
.text-confirm-ordine{
  font-weight: bold;
  font-size: 20px;
}
.color-gray{
  color: #858796;
}
.font-size-large{
  font-size: large;
}
.color-green{
  color: #1cc88a;
}
.alert-dismissible .close:hover{
  color: #806520;
    background-color: #fdf3d8;
    border-color: #fceec9;
}
.font-size-small{
  font-size: small!important;
}
.min-height-250{
  min-height: 250px;
}


        body {
        scroll-behavior: smooth;
      }
  
      section {
        padding: 60px 0;
      }
  
      #hero {
        
        background-color: #1cc88a;
        color: #ffffff;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
      }

      #register {
        height: 90vh;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: center;
        width: 100%;
      }

      #services {
        height: 50vh;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: center;
        width: 100%;
      }
      

      #contact {
        height: 50vh;
        background-color: #36b9cc!important;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: center;
        width: 100%;
        color:#ffffff
      }
  
      #services, #about, #testimonials {
        background-color: #f9f9f9;
      }
  
      footer {
        background-color: #343a40;
        color: white;
        padding: 20px 0;
      }

      .btn-assistenza{
        background-color: #ffffff;
        color: #36b9cc;
        padding: 10px;
        border-radius: 0.35rem;
        border-color: #36b9cc;
      }
.btn-dark{
  color: #fff;
    background-color:#0E1449;
    border-color: #0E1449;
    ;
}

.btn-success{
  color: #fff;
  background-color: #0E1449;
  border-color: #0E1449;
}
.color-icon-green {
  color: #0E1449;
}
.btn-danger {
  color: #0E1449;
  background-color: #D9D9D9;
  border-color: #D9D9D9;
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #0E1449;
  border-color: #0E1449;
}
.btn-info {
  color: #fff;
  background-color: #0E1449;
  border-color: #0E1449;
}
.btn-primary:hover {
  color: #0E1449;
  background-color: #D9D9D9;
  border-color: #D9D9D9;
}
.btn-primary:hover {
  color: #0E1449;
  background-color: #D9D9D9;
  border-color: #D9D9D9;
}
.margin-b-0{
  margin-bottom: 0px!important;
}
.padding-l-0{
  padding-left: 0px;
}
.icon-menu{
  
  padding-right: 10px;
}
.tab-bg{
  background-color: #D9D9D9!important;
}
.tab-dim{
  width: 100%;
}
.nav-link.active{
  background-color: #ffffff!important;
}
a {
  color: #0E1449;
  text-decoration: none;
  background-color: transparent;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: none;
  border-top-left-radius: 0px;
  border-top-right-radius: none;
  color: #596262;
}
.nav-tabs {
  border-bottom: none;
}
.nav-tabs {
  margin-bottom: 0px!important;
}
#ricerca-tabs-tab-tab1{
  width: 50%;
}
#ricerca-tabs-tab-tab2{
  width: 50%;
}
#ricerca-tabs-tab-tab1:hover{
  color: #0E1449;
}
#ricerca-tabs-tab-tab2:hover{
  color: #0E1449;
}

.box-message-general{
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.98px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0E1449;

}
.box-message-general-info{
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0E1449;

}
.box-message-span{
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0E1449;

}
.box-message-span-s{
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0E1449;


}
.box-message-span-shop{
  font-family: Ubuntu;
  font-size: 22px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0E1449;


}
.box-message-padding{
  padding-top: 80px;
  padding-bottom: 50px;
}
.padding-t-50{
  padding-top: 50px;
}
.box-message-dim{
  max-width: 540px!important;
  margin: auto;
}
.button-bg-gray{
  background: #D9D9D9!important;
  border-color: #D9D9D9!important;
}
.button-bg-blue{
  background: #0E1449!important;
  border-color: #0E1449!important;
}
.color-blue{
  color: #0E1449!important;
}
.box-info-ricerca{
  display: inline-flex;
  gap: 0px;
  border-radius: 10px;
  background: #EEEFFC;
  margin: 5px;
  font-size: 12px;
  padding: 5px;
  color: #0E1449;
}
.box-info-ricerca-close{
  padding: 3px;
  margin-left: 5px;
  color: #0E1449;
}
div .btn-blue{
  background: #0E1449!important;
  border-color: #0E1449!important;
  color: #FFFFFF!important;
}
.color-text-gray{
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.38px;
  // text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #596262;

}
.color-text-gray-bold{
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #596262;

}
.color-count{
  font-family: Ubuntu;
font-size: 14px;
font-weight: 400;
line-height: 16.09px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color:#596262;
}
.btn-cancel{
  width: 98px!important;
  height: 32px!important;
  gap: 0px!important;
  border-radius: 7px!important;
  align-items: center!important;
  justify-content: center!important;
  padding: 0!important;
  height: '40px'!important;
  line-height: '1'!important;

}
.btn-success-my{
  width: 123px!important;
  height: 32px!important;
  gap: 0px!important;
  border-radius: 7px!important;
  // display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  padding: 0!important;
  height: '40px'!important;
  line-height: '1'!important;
}
.ricerca-effettuata{
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.border-title{
  border-bottom: 1px solid #596262
}
.border-title-top{
  border-top: 1px solid #596262
}
.button-vis-bil{
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.09px;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color:#0E1449;
  cursor: pointer;
}
.button-disable{
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.09px;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color:#D9D9D9;
}

.margin-r-5{
  margin-right: 5px;
}
.margin-b-10{
  margin-bottom: 10px;
}
.margin-t-5{
  margin-top: 5px;
}
.box-ris-etichetta{
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #596262;
  padding-top: 20px;

}
.box-ris-dato{
  font-family: Ubuntu;
font-size: 16px;
font-weight: 500;
line-height: 18.38px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #0E1449;


}
.padding-t-20{
  padding-top: 20px;
}
.padding-t-5{
  padding-top: 5px;
}
.padding-t-15{
  padding-top: 15px;
}
.padding-t-0{
  padding-top: 0px;
}
.btn-success {
  color: #fff;
  background-color: #0E1449!important;
  border-color: #0E1449!important;
}
.btn-secondary {
  color: #0E1449!important;
  background-color: #D9D9D9!important;
  border-color: #D9D9D9 !important;
}
.btn-danger {
  color: #0E1449!important;
  background-color: #D9D9D9!important;
  border-color: #D9D9D9 !important;
}
.text-align-left{
  text-align: left!important;
}
.color-gray-pg{
  color: #596262!important;
}
.paddin-left-0{
  padding-left: 0px!important;
}
.color-gray-pg-2{
  color: #596262;
}
.nav-tabs .nav-link.active span{
  color: #0E1449 !important;
}
.padding-bottom10{
  padding-bottom: 10px;
}
.font-icon-35{
  font-size: 35px;
}
.text-align-right-list{
  text-align: right!important;
}
.posizioni-lista:hover {
  color: #596262;
  font-weight: bold;
}
.btn-info {
  color: #fff;
  background-color: #0E1449!important;
  border-color: #0E1449!important;
}
.pacchetto-aggiuntivo:hover {
  color: #596262;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .page-setting .col-sm-2{
    width: 28%;
  }
  .page-setting .col-sm-10{
    width: 72%;
  }
  // .sidebar-toggled .page-setting .col-sm-2{
  //   display: none;
  // }
  .sidebar-toggled .page-setting .sidebar-sm{
    display: none;
  }
  .sidebar-toggled .page-setting .display-tog{
    display: none;
  }

  .p-left-15-sm{
    padding-left: 15px;
  }
  
  .sidebar-toggled .page-setting .col-sm-10{
    padding: 10px;
    width: 100%;
  }
  .p-ricerche .col-sm-6{
    width: 50%!important;
  }
  .p-ricerche button{
    font-size:14px!important;
  }
  .icon-order-display{
    display: none;
  }
  .icon-archivio-display{
    display: block!important;
  }
  .icon-archivio-displays{
    display: none!important;
  }
  .doc-req{
    margin: auto!important;
    padding:10px;
  }
  .doc-req .col-sm-6{
    width: 50%;
  }
  .text-center-sm{
    text-align: center;
  }
  .margin-btn-pay{
    margin-top: 10px;
  }
  .carrello .col-sm-1{
    width: 20%!important;
  }
  .carrello .col-sm-9{
    width: 80%!important;
  }
  .carrello .car-2{
    width: 100%!important;
  }
  .cost .col-sm-2{
    width: 50%!important;
  }
  .carrello .boldtesto{
    text-align: right;
  }
  .cost{
    text-align: right;
  }
  .cost-iva{
    text-align: left;
  }
  .carrello .posizioni-lista{
    font-weight: 200!important;
  }
  .car-2 .boldtesto{
    font-weight: 200!important;
  }
  .pack .col-sm-6{
    width: 50%!important;
  }
  .none{
    display: none;
  }
  .pack{
    margin-top: 15px;
  }
  .pacchetti, .val{
    text-align: right;
    font-weight: 200;
  }
  .pacchetti, .name{
    text-align: left;
    font-weight: 200;
  }
  .pacchetti .col-sm-6{
    width: 50%!important;
    
  }
  .fontwb{
    font-weight: bold!important;
  }
  .fontw2{
    font-weight: 200!important;
  }

  .dimcol2{
    width: 50%!important;
  }
  .dimcol2100{
    width: 100%!important;
  }
  .dimcol6{
    width: 50%!important;
  }
  .margin-top-s-20{
    margin-top: 20px;
  }
  .text-align-sm-center{
    text-align: center!important;
  }
  .navbar-nav{
    height: 100%;
  }
  .div-sm-pl-10px{
    padding-left: 10px;
  }
  .sidebar-toggled .div-sm-plr{
    padding-left: 25px;
    padding-right: 10px;
  }
  .text-center-ass{
    text-align: center!important;
  }

}

.icon-archivio-display{
  display: none;
}
.icon-archivio-displays{
  display: block;
}
.user-reg{
  width: 550px;
  max-width: 550px;
}
.box-reg {
  background: linear-gradient(250.21deg, #0e1449 10.28%, #070b32 63.23%);
  border-radius: 10px;
  padding: 25px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.s1-image-text-reg {
  padding-left: 135px;
  max-width: 500px;
}
.alert-warning {
  color: #0c1142!important;
  background-color: #d9d9d9!important;
  border-color: #d9d9d9!important;
}
.alert-success {
  color: #0c1142!important;
  background-color: #d9d9d9!important;
  border-color: #d9d9d9!important;
}
.box-reg .form-group {
  margin-bottom: 1.2rem;
}
form.user .form-control-user {
  font-size: 0.8rem;
  border-radius: 10px!important;
  padding: 1.5rem 1rem;
}
form.user .btn-user {
  font-size: 0.8rem;
  border-radius: 10px!important;
  padding: 0.75rem 1rem;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #0E1449!important;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color:  #0E1449!important;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: none!important; 
}
.font-24{
  font-size: 24px;

}

.btn-assistenza{
  border-radius: .25rem;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #0e1449;
    margin: auto;
    display: block;
    height: 45px;
}

/* Aumentare la dimensione della checkbox */
input[type="checkbox"].form-check-input {
  width: 24px; /* Larghezza */
  height: 24px; /* Altezza */
  cursor: pointer;
  appearance: none; /* Rimuove lo stile predefinito */
  border: 2px solid #0E1449; /* Bordo */
  border-radius: 4px; /* Angoli arrotondati */
  background-color: #f0f0f0; /* Colore di background */
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
}

/* Stato quando la checkbox è selezionata */
input[type="checkbox"].form-check-input:checked {
  background-color: #0E1449; /* Colore di sfondo quando selezionata */
  border-color: #0E1449; /* Colore del bordo quando selezionata */
}

input[type="checkbox"].form-check-input:checked::before {
  content: "✓"; /* Aggiunge una spunta */
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.padding-t-5{
  padding-top: 5px;
}
.text-center-ass .btn-invio-assistenza{
  width: 120px;
    background-color: #0E1449 !important;
    color: #ffffff !important;
    border-color: #0E1449 !important;
    margin-top: 30px;
}
.padding-t-5 label{
  padding-top: 5px;;
}
.padding-b-30{
  padding-bottom: 30px;;
}
.margin-t-50{
  margin-top: 30px;
}
.termini-shop{
  margin: auto;
    display: block;
    padding-top: 30px;
}
.label-termini-shop{
  text-align: center;
    padding-top: 5px;
}
.crediti{
  padding-top: 5px;
    margin-bottom: 5px;
    text-decoration: underline;
}
.text-underline{
  text-decoration: underline;
}
.font-12{
  font-size: 12px;
}

.font-16{
  font-size: 16px!important;
}

.button-search-icon{
  background-color: #0e1449!important;
    border-radius: 0px 10% 10% 0%!important;
}
.color-white{
  color: #ffffff;
}
.color-white-i{
  color: #ffffff!important;
}
.p-top-8{
  padding-top: 8px;
}
.bg-blue{
  background-color: #0e1449!important;
}
.heigth-50{
  height: 50px!important;
}
.message-align-j{
  padding: 0 40px;
  text-align: justify!important;
}
.lista-pac{
  margin: auto;
  padding: 0 40px;
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%230c1142' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E")!important;
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #0c1142 !important;
  padding-right: calc(1.5em + .75rem) !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #0c1142!important;
  padding-right: calc(1.5em + .75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23596262%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23596262%27 stroke=%27none%27/%3e%3c/svg%3e")!important;
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
.box-message-padding-noimp {
  padding-top: 15px;
  padding-bottom: 0px;
}
.p-bottom-15{
  padding-bottom: 15px;
}
.p-10{
  padding:10px!important
}