

// .menu-basic{
//     width: 1424px;
//     height: 105px;
//     gap: 0px;
//     opacity: 0px;
//     background-color: #ffffff;
// }
// .center-block{
//     margin: auto;
//     display: block;
// }
// .logo-public{
//     width: 154px;
//     height: 37.76px;
//     top: 42px;
//     left: 125px;
//     gap: 0px;
//     opacity: 0px;
//     position: fixed;

// }
// .menu-servizi{
//     width: 80px;
//     height: 30px;
//     top: 47px;
//     left: 884px;
//     gap: 0px;
//     opacity: 0px;
//     position: fixed;
// }
// .menu-prezzi{
//     width: 80px;
//     height: 30px;
//     top: 47px;
//     left: 1040px;
//     gap: 0px;
//     opacity: 0px;
//     position: fixed;

// }
// .menu-chisiamo{
//     width: 94px;
//     height: 30px;
//     top: 47px;
//     left: 1210px;
//     gap: 0px;
//     opacity: 0px;
//     position: fixed;

// }
// .font-menu{
//     ////font-family: Ubuntu;
//     font-size: 20px;
//     font-weight: 400;
//     line-height: 30px;
//     letter-spacing: -0.022em;
//     text-align: center;
//     text-underline-position: from-font;
//     text-decoration-skip-ink: none;
// }
// .image-principale{
//     width: 1424px;
//     height: 576px;
//     top: 105px;
//     gap: 0px;
//     opacity: 0px;
//     position: fixed;

// }
// .center-block-2 {
//     display: flex;
//     justify-content: center;  /* Centra orizzontalmente */
//     align-items: center;      /* Centra verticalmente */
//     height: 100vh;            /* Imposta l'altezza della pagina (viewport) */
//   }
  
// .image-principale-2 {
//     max-width: 100%;  /* Per fare in modo che l'immagine non esca dal contenitore */
//     height: auto;     /* Mantiene le proporzioni dell'immagine */
//     position: fixed;
//   }

// .text-image-section{
//     width: 462px;
//     height: 104px;
//     top: 207px;
//     left: 125px;
//     gap: 0px;
//     opacity: 0px;
//     position: fixed;

// }

// .style-text{
//     ////font-family: Ubuntu;
//     font-size: 40px;
//     font-weight: 700;
//     line-height: 52px;
//     letter-spacing: -0.022em;
//     text-align: left;
//     text-underline-position: from-font;
//     text-decoration-skip-ink: none;
//     color: #0E1449;

// }

// .s1-text{
//     width: 600px;
//     height: 144px;
//     top: 341px;
//     left: 125px;
//     gap: 0px;
//     opacity: 0px;
//     position: fixed;
// }
// .s2-text{
//     width: 600px;
//     height: 144px;
//     top: 341px;
//     left: 125px;
//     gap: 0px;
//     opacity: 0px;
//     position: fixed;
// }
// .s2-style-text{
//     //styleName: Contenuto Hero;
//     ////font-family: Ubuntu;
//     font-size: 24px;
//     font-weight: 300;
//     line-height: 36px;
//     letter-spacing: -0.019em;
//     text-align: left;
//     text-underline-position: from-font;
//     text-decoration-skip-ink: none;
//     color:#000000

// }
// .s2-topografy{
//     //styleName: Contenuto Hero;
// ////font-family: Ubuntu;
// font-size: 24px;
// font-weight: 300;
// line-height: 36px;
// letter-spacing: -0.019em;
// text-align: left;
// text-underline-position: from-font;
// text-decoration-skip-ink: none;

// }
// .box-login{
//     width: 403px;
//     height: 446px;
//     top: 167px;
//     left: 896px;
//     gap: 0px;
//     opacity: 0px;
//     position: fixed;

// }
// .background-linear{
//     background: linear-gradient(250.21deg, #0E1449 10.28%, #070B32 63.23%);
// }

// .box-login-title{
//     width: 327px;
//     height: 36px;
//     top: 198px;
//     left: 940px;
//     gap: 0px;
//     opacity: 0px;
//     position: fixed;
//     color: #ffffff;
// }
// .box-login-text{
//     ////font-family: Ubuntu;
//     font-size: 24px;
//     font-weight: 700;
//     line-height: 36px;
//     letter-spacing: -0.022em;
//     text-align: center;
//     text-underline-position: from-font;
//     text-decoration-skip-ink: none;

// }

// .text-box{
//     width: Fixed (283px)px;
//     height: Hug (148px)px;
//     top: 250px;
//     left: 956px;
//     gap: 14px;
//     opacity: 0px;
//     position: fixed;

// }
// .label-text{
//     //styleName: contenuto Sezioni;
//     ////font-family: Ubuntu;
//     font-size: 16px;
//     font-weight: 300;
//     line-height: 24px;
//     letter-spacing: -0.011em;
//     text-align: left;
//     text-underline-position: from-font;
//     text-decoration-skip-ink: none;
//     color: #D9D9D9;
//     ;

// }
// .button-login{
//     width: 112px;
//     height: 33px;
//     top: 438px;
//     left: 1041px;
//     gap: 0px;
//     border-radius: 6px;
//     opacity: 0px;
//     position: fixed;
//     background: #D9D9D9;


// }
// .text-button-login{
//     ////font-family: Ubuntu;
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 21px;
//     letter-spacing: -0.022em;
//     text-align: center;
//     text-underline-position: from-font;
//     text-decoration-skip-ink: none;
//     color: #0E1449;
// }
// .boxlogin-text-cred{
//     width: 319px;
//     height: 48px;
//     top: 496px;
//     left: 944px;
//     gap: 0px;
//     opacity: 0px;
//     position: fixed;

// }
// .boxlogin-text{
//     ////font-family: Ubuntu;
//     font-size: 16px;
//     font-weight: 300;
//     line-height: 24px;
//     letter-spacing: -0.011em;
//     text-align: center;
//     text-underline-position: from-font;
//     text-decoration-skip-ink: none;
//     color: #D9D9D9;
//     ;

// }
// .boxlogin-register{
//     ////font-family: Ubuntu;
//     font-size: 16px;
//     font-weight: 700;
//     line-height: 24px;
//     letter-spacing: -0.011em;
//     text-align: center;
//     text-decoration-line: underline;
//     text-decoration-style: solid;
//     text-underline-position: from-font;
//     text-decoration-skip-ink: none;

// }
// .boxlogin-datamissed{
//     width: 88px;
// height: 18px;
// top: 569px;
// left: 1053px;
// ////font-family: Ubuntu;
// font-size: 12px;
// font-weight: 400;
// line-height: 18px;
// letter-spacing: -0.022em;
// text-align: center;
// text-decoration-line: underline;
// text-decoration-style: solid;
// text-underline-position: from-font;
// text-decoration-skip-ink: none;
// position: fixed;
// color: #D9D9D9;


// }
/* Contenitore principale */
.center-block {
    display: flex;
    flex-direction: column; /* Impilare elementi verticalmente */
    align-items: center;    /* Centrare orizzontalmente */
    width: 100%;
    margin: 0 auto;
    
  }
  
  /* Menu in alto */
  .menu-basic {
    display: flex;
    justify-content: space-between; /* Distribuisce gli elementi */
    align-items: center;
    // width: 100%; /* Adatta il menu alla larghezza dello schermo */
    // max-width: 100%;
    padding: 10px 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    min-height: 105px;
  }
  
  /* Logo */
  .logo-public img {
    max-width: 150px;
    height: auto;
  }
  
  /* Menu voci */
  .menu-basic  {
    // margin: 0 15px;
    font-family: Ubuntu, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
  }
  
  /* Sezione principale */
  .center-block-2 {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Due colonne */
    gap: 20px;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 40px auto;
  }
  
  .image-principale {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  /* Testo principale */
  .style-text {
    font-family: Ubuntu, sans-serif;
    font-size: 2rem;
    font-weight: 700;
    color: #0e1449;
    margin-bottom: 20px;
  }
  
  .s1-text,
  .s2-style-text {
    // font-family: Ubuntu, sans-serif;
    // font-size: 1rem;
    // font-weight: 300;
    // line-height: 1.5;
    // color: #000000;
    // margin-bottom: 10px;

    //styleName: Contenuto Hero;
    font-family: Ubuntu;
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: -0.019em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;

  }
  
  /* Box login */
  .box-login {
    background: linear-gradient(250.21deg, #0e1449 10.28%, #070b32 63.23%);
    border-radius: 10px;
    padding: 25px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .box-login-title {
    font-family: Ubuntu, sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .text-box {
    width: 100%;
  }
  
  .label-text {
    font-family: Ubuntu, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: #d9d9d9;
  }
  
  .button-login {
    width: 100%;
    background: #d9d9d9;
    color: #0e1449;
    border-radius: 6px;
    // padding: 10px;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
  }
  
  /* Link e testi aggiuntivi */
  .boxlogin-text-cred {
    font-family: Ubuntu, sans-serif;
    font-size: 0.9rem;
    font-weight: 300;
    color: #d9d9d9;
    text-align: center;
    margin-top: 20px;
  }
  
  .boxlogin-register {
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
  
  /* Media query per schermi piccoli */
  @media (max-width: 768px) {
    .menu-basic {
      flex-direction: column;
      gap: 10px;
    }
  
    .center-block-2 {
      grid-template-columns: 1fr; /* Una sola colonna */
      gap: 20px;
    }
  
    .style-text {
      font-size: 1.5rem;
    }
  
    .s1-text,
    .s2-style-text {
      font-size: 0.9rem;
    }
  
    .box-login {
      padding: 15px;
    }
    .user-reg {
      width: auto!important;
      max-width: max-content;
  }
  .s1-image-text-reg-center{
    padding: 0!important;
    text-align: center!important;

  }
    
  }

  .font-menu {
    // font-family: Ubuntu, sans-serif;
    // font-size: 1.2rem;
    // font-weight: 400;
    // margin: 0; /* Elimina il margine verticale */
    ////font-family: Ubuntu;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.022em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 20px;
    color: #1E1E1E;


  }
  
  .d-flex.gap-3 > .font-menu {
    margin-left: 30px; /* Puoi regolare questo valore per controllare la distanza */
    margin-right: 30px;
  }
  .px-custom {
    padding-left: 125px;
    padding-right: 125px;
  }
  .img-principal{
    background-image: url(../asset/asset_principal.png);
  }
  .img-jmyimprese{
    background-image: url(../asset/junion.png);
  }
  .s1-image-text{
    padding-left: 135px;
    padding-top: 100px;
    max-width: 500px;
  }
  .s2-image-text{
    padding-left: 135px;
    padding-right: 20px;
  }

  .boxlogin-datamissed{
    //     width: 88px;
    // height: 18px;
    // top: 569px;
    // left: 1053px;
    ////font-family: Ubuntu;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.022em;
    text-align: center;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #D9D9D9;
    cursor: pointer;
  }
  .box-login-px-25{
    padding-left: 25px;
    padding-right: 25px;

  }
  .box-login-button{
    width: 115px;
    height: 35px;
    margin: auto;
    display: block;
    margin-top: 20px;
  }
  .box-login-span{
    margin-top: 10px;
  }
  .box-login-button:hover{
    background-color: #D9D9D9;
    color: #0e1449;
  }
  .box-login-py-50{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  // .box-screen-img{
  //   width: 379px;
  //   height: 446px;
  //   gap: 0px;
  //   opacity: 0px;

  // }
  // .box-screen-img-dim-post{
  //   width: 270px;
  //   height: 294px;
  //   gap: 0px;
  //   opacity: 0px;

  // }
  // .box-screen-img-prima{
  //   padding-left: 125px;
  //   padding-top: 100px;
  //   padding-bottom: 50px;
  // }
  // .padding-ty-150{
  //   padding-top: 150px;
  // }


  .box-screen-img-prima {
    padding-left: 125px!important;
    padding-top: 100px;
    padding-bottom: 50px;
    position: relative; /* Permette lo spostamento orizzontale */
    z-index: 2; /* Assicura che la prima immagine sia sopra la seconda */
     /* Sposta la prima immagine verso sinistra di 50px */
}

.padding-ty-150 {
    padding-top: 150px;
    position: relative; /* Mantiene il posizionamento della seconda immagine */
    z-index: 1; /* Assicura che la seconda immagine stia sotto la prima */
}

.box-screen-img {
    width: 379px;
    height: 446px;
    gap: 0px;
    opacity: 1; /* Cambia a 1 per rendere visibile l'immagine */
}

.box-screen-img-dim-post {
    width: 270px;
    height: auto;
    // height: 294px;
    gap: 0px;
    opacity: 1; /* Cambia a 1 per rendere visibile l'immagine */
    // left: -65px;
    left: -80px;
    position: absolute;
}
.box-action-bg{
  background: linear-gradient(250.21deg, #0E1449 10.28%, #070B32 63.23%);
}
.box-action-title{
  //styleName: Titolo Sezione;
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.019em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.box-action-title-dim{
  width: 495px;
  height: 76px;
  top: 742px;
  left: 804px;
  gap: 0px;
  // text-align: center;
  margin: auto;
}
.text-white-public{
  color: #ffffff;
}
.m-top-50{
  margin-top: 50px;
}
.p-top-50{
  padding-top: 50px;
}
.p-top-30{
  padding-top: 30px;
}
.p-top-20{
  padding-top: 20px;
}
.p-left-12{
  padding-left: 12px;
}
.m-bottom-10{
  margin-bottom: 10px;
}
.m-bottom-5{
  margin-bottom: 5px;
}
.p-top-100{
  padding-top: 100px;
}
.p-top-80{
  padding-top: 80px;
}
.p-bottom-50{
  padding-bottom: 50px;
}
.p-bottom-30{
  padding-bottom: 30px;
}
.p-bottom-100{
  padding-bottom: 100px;
}
.box-action-individua{
  //styleName: Titoletto Paragrafo;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.011em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;

}
.box-action-individua-text{
  //styleName: contenuto Sezioni;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.011em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;


}
.box-action-individua-img{
  margin: auto;
  display: block;
  // padding-top: 25px;
  padding-left: 25px;
}
.box-action-select{
  background: #262c59
}
.box-info{
  //styleName: Contenuto Hero;
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: -0.019em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}
.box-info-text{
  max-width: 1080px;
  margin: auto;
  display: block;
}
.box-color-black{
  color: #000000;
}
.box-bg-light{
  background: #FEFEFE;
}
.box-testimonial{
  background: #EEEFFC;
  height: 235px;
}
.box-testimonial-img-1{
  width: 52px;
  height: 52px;
  gap: 0px;
  border-radius: 480px;
}
.box-testimonial-title{
  //styleName: Titoletto Paragrafo;
font-family: Ubuntu;
font-size: 18px;
font-weight: 700;
line-height: 24px;
letter-spacing: -0.011em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #0E1449;

}
.box-testimonial-subtitle{
  font-family: Ubuntu;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: -0.011em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #0E1449;
}

.box-testimonial-dim{
  width: 1172px;
  height: 139px;
  gap: 0px;
  margin: auto!important;

}
.box-testimonial-text{
  //styleName: Italico;
font-family: Ubuntu;
font-size: 16px;
font-style: italic;
font-weight: 300;
line-height: 24px;
letter-spacing: -0.011em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.box-testimonial-pos-img-text{
  float: left;
    margin-right: 10px;
}

.container-custom{
  width: 100%;
  // padding-right: 0.75rem;
  // padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

.p-none{
  padding: 0px;
}
.t-align-right{
  text-align: right;
}
.t-align-center{
  text-align: center;
}
.box-piani-bg{
  background: linear-gradient(250.21deg, #0E1449 10.28%, #070B32 63.23%);
  padding-bottom:15px;

}
.box-piani-title{
  //styleName: Titolo Sezione;
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.019em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FEFEFE;

}
.box-piani-box{
  width: 335px;
  height: 335px;
  gap: 0px;
  border-radius: 10px;

}
.box-piani-dim-boxes{
  max-width: 1200px;
  height: 350px;
  gap: 0px;
  border-radius: 10px;
  margin: auto;
}
.box-piani-boxes-header{
  width: 335px;
  height: 89px;
  gap: 0px;
  border-radius: 10px 10px 0px 0px;
  background: #EEEFFC;

}
.p-lr-30{
  padding-left: 30px;
    padding-top: 30px;
}
.p-lr-15{
  padding-left: 15px;
    padding-right: 15px;
}

.box-piani-span{
  font-family: Ubuntu;
font-size: 12px;
font-weight: 400;
line-height: 13.79px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #0E1449;

}
.box-piani-row-text{
  font-family: Ubuntu;
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #0E1449;
padding-left: 30px;

}
.box-piani-num{
  font-family: Ubuntu;
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: right;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #0E1449;
padding-right: 30px;
}
.m-lr-7-5{
  margin-left: 7.5px!important;
  margin-right: 7.5px!important;
}
.box-piani-radius{
  border-radius: 10px;
  height: 100%;
}
.box-piani-registrati{
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.011em;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FEFEFE;


}
.box-piani-registrati-span{
  font-family: Ubuntu;
font-size: 16px;
font-weight: 300;
line-height: 24px;
letter-spacing: -0.011em;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FEFEFE;


}
.box-piani-jabsolute{
  position: absolute!important;
  padding-left: 0px!important;
}
.box-loghi{
  max-width: 699px;
  height: 288px;
  gap: 0px;
  opacity: 0px;
  

}
.box-loghi-text{
  //styleName: Contenuto Hero;
font-family: Ubuntu;
font-size: 24px;
font-weight: 300;
line-height: 36px;
letter-spacing: -0.019em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #1E1E1E;

}

.box-loghi-button{
  width: 196px;
height: 48px;
gap: 0px;
border-radius: 7px;
font-family: Ubuntu;
font-size: 20px;
font-weight: 500;
line-height: 30px;
letter-spacing: -0.019em;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #0E1449;
background-color: #D9D9D9;
border: none;




}

.box-footer-bg{
  background: #D9D9D9;
}
.box-footer-col1-text{
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.022em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;

}
.box-footer-logo-tecno{
  width: 208px;
  height: 25.23px;
  gap: 0px;

}
.box-footer-link{
  font-family: Ubuntu;
font-size: 14px;
font-weight: 500;
line-height: 21px;
letter-spacing: -0.022em;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
}
.box-footer-link-color{
  color: #000000!important;
}
.box-footer-text-col3{
  font-family: Ubuntu;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: -0.022em;
text-align: right;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000000!important;

}
  
.box-footer-logo_2{
  width: 60px;
height: 17.39px;
gap: 0px;


}
.box-footer-logo_1{
  width: 84px;
height: 30px;
gap: 0px;

}
.box-footer-space{
  padding-top: 50px;
  padding-right: 100px;
  padding-left: 100px;
}
.image-right{
  text-align: right;
}
.box-copy{
  background: linear-gradient(250.21deg, #0E1449 10.28%, #070B32 63.23%);
}
.box-copy-logo{
  width: 6%;
}
.box-copy-text{
  font-family: Ubuntu;
font-size: 10px;
font-weight: 400;
line-height: 15px;
letter-spacing: -0.022em;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #ffffff;
}

.control-d{
  display: flex!important;
  margin: auto!important;
}
.accedi-pos{
  margin: auto!important;
  display: block!important;
}
.control-r{
  display: none!important;
}
.control-r-t{
  display: none!important;
}
.btn-primary:hover {
  color: #0E1449!important;
  background-color: #D9D9D9!important;
  border-color: #D9D9D9!important;
}
.btn-primary {
  color: #0E1449!important;
  background-color: #D9D9D9!important;
  border-color: #D9D9D9!important;
}

@media only screen and (max-width: 600px) {
  .s1-image-text{
    padding: 10px;
    text-align: center;
    padding-bottom: 5px;
    font-family: Ubuntu;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    letter-spacing: -0.022em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
  .s2-image-text{
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 10px;
    padding-top: 0%!important;
    text-align: center;

  }
  .section-image{
    display: none;
  }
  .p-sm-0{
    padding-left: 0px;
  }
  .dim-custom-20{
    width: 20%!important;
  }
  .dim-custom-80{
    width: 80%!important;
  }
  .text-center-sm{
    text-align: center;
  }
  .box-action-title-dim {
    width: auto; 
    height: auto;
    // opacity: 0px;
    /* margin: auto; */
    text-align: center;
    padding-bottom: 30px;
}
.box-action-individua-img {
  margin: auto;
  display: block;
  padding-top: 0px;
  padding-left: 25px;
}

.carousel-inner {
  text-align: center;
}
.carousel-item img {
  max-width: 150px;
  margin: 0 auto 15px;
}
.box-testimonial-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.box-testimonial-subtitle {
  font-size: 0.9rem;
  color: gray;
}
.box-testimonial-text {
  font-size: 1rem;
  margin-top: 10px;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #0E144900!important;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}
.carousel-indicators {
  bottom:-50px!important;
  margin-top: 30px!important;

}
.carousel-item img {
  max-width: 150px;
  margin: auto;
  margin-left: 15px;
  margin-right: 10px;
}
.carousel-item{
  padding-top: 30px;
}
.box-testimonial-text {
  font-size: 1rem;
  margin-top: 10px;
  padding: 10px;
}
.carousel-indicators li {
  background-color: #0E1449!important;
}
.control-d{
  display: none!important;
}
.control-r{
  display: initial!important;
}
.control-r-t{
  display: initial!important;
}
.control-r .col-sm-9{
  width: 70%;
  text-align: left;
}
.control-r .col-sm-3{
  width: 30%;
}
.control-r  .carousel-indicators li {
  background-color: #ffffff!important;
}
.box-loghi-text{
  padding-left: 10px!important;
  padding-right: 10px!important;
  text-align: center;
}
.img-position{
  text-align: center;
}
.img-dim{
  width: 50%!important;
  text-align: center;
}
.p-sm-30{
  padding-top: 30px!important;
}
.p-sm-50{
  padding-bottom: 50px!important;
}
.box-footer-space {
  padding-top: 50px;
  padding-right: 15px!important;
  padding-left: 15px!important; 
  text-align: center;
}
.box-footer-col1-text{
  text-align: center!important;
}
.box-footer-text-col3{
  text-align: center!important;
}
.image-right{
  text-align: center!important;
}
.space-sm{
  margin-bottom: 30px;
}
.position-sm{
  margin: auto!important;
  display: block!important;
}
.position-sm-text{
  text-align: center!important;
  padding-bottom: 10px;
  display: block;
}
.paddind-lr-m-15{
  padding-left: 15px!important;
  padding-right: 15px!important;
}
.full-width{
  width: 100%;
  min-height: auto!important;
  padding-top: 15px;
  padding-bottom: 15px;
}
.min-height-auto{
  min-height: auto!important;
}
.icon-bar-sm{
  font-size: 35px;
  color: #0E1449;
}

}
/* Nascondi mobile per desktop e viceversa */
.nav-desktop {
  display: none!important;
}

.nav-mobile {
  display: flex; /* O block, a seconda del layout */
}

/* Mostra desktop sopra i 768px (tablet e desktop) */
@media screen and (min-width: 768px) {
  .nav-desktop {
    display: flex!important; /* O block */
  }

  .nav-mobile {
    display: none!important;
  }
}

.underline-link{
  cursor: pointer;
}
.text-uppercase{
  text-transform: uppercase;
}
